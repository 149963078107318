export const useProps = (props = {}) => ({
  context: {
    schema: '$schema',
    formType: '$formType',
    formData: '$formData',
    conveyancingCase: '$conveyancingCase',
    saleReadyPack: '$saleReadyPack',
    quoteCase: '$quoteCase',
    clients: '$clients',
    employeeCase: '$employeeCase',
  },
  ...props,
})
