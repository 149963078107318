import SectionCard from '~/components/SectionCard.vue'
import innerComponents from '~/schemas/components/gift-declaration/common/inner-component-loop'

export default function giftSolvencyDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: 2, title: 'Gift Solvency Declaration' }),
    attrs: {
      class: 'mb-4',
    },
    children: [
      {
        $el: 'div',
        attrs: { class: 'text-xs mt-4 mb-4' },
        children:
          'I confirm the following statements to be true and that I will provide proof of evidence if requested that at the time of signing this document and when I transfer my gift:',
      },
      ...innerComponents([
        {
          number: '2.1.',
          text: 'I understand that AVRillo does not legally represent or act for me but works for the Donee only and therefore is unable to advise, nor speak to, me either on the contents of this declaration or any other documents sent to me to sign.',
        },
        {
          number: '2.2.',
          text: 'I confirm that I am solvent. That by making my gift, it will not make me insolvent.',
        },
        {
          number: '2.3.',
          text: 'I confirm that my gift is not made to avoid paying or defrauding my creditors, and nor will it have that effect.',
        },
        {
          number: '2.4.',
          text: 'That my income exceeds my outgoings.',
        },
        {
          number: '2.5.',
          text: 'That I can repay all debts.',
        },
        {
          number: '2.6.',
          text: 'That my assets exceed my liabilities at',
        },
        {
          number: '2.7.',
          text: 'I confirm that I am not aware of any pending proceedings against me nor of any circumstances which might either make me insolvent or bankrupt. I do not believe that I am at risk of becoming insolvent or going into bankruptcy in the foreseeable future. Nor am I aware of any circumstances that should have reasonably put me on notice of bankruptcy risk, whether I file for my own bankruptcy or bankruptcy proceedings being taken against me.',
        },
        {
          number: '2.8.',
          text: 'I confirm that if, after I sign this document and transfer my money, I subsequently learn that I may be at risk of any of the above matters, I will immediately notify AVRillo and the Donee.',
        },
        {
          number: '2.9.',
          text: 'I also understand that this, my gift solvency declaration, may also be used to obtain a gift insurance policy in respect of this gift for the Donee to cover the risk of creditors, my successors, administrators making a claim for an interest or right in the gift money I transfer or in the property purchased by the Donee in their name with my gift.',
        },
      ]),
    ],
  }
}
