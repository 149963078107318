import SectionCard from '~/components/SectionCard.vue'
import TextWithClients from '~/components/TextWithClients.vue'
import { useProps } from '~/composables/useProps.js'
import innerComponents from '~/schemas/components/gift-declaration/common/inner-component-loop'

export default function giftUnderstandingDeclaration() {
  return {
    $cmp: SectionCard,
    props: useProps({ tab: 1, title: 'Gift Understanding declaration' }),
    attrs: {
      class: 'mb-4',
    },
    children: innerComponents([
      {
        number: '1.1.',
        text: 'I understand that AVRillo does not legally represent or act for me but works for the Donee only and therefore is unable to advise, nor speak to, me either on the contents of this declaration or any other documents sent to me to sign.',
      },
      {
        number: '1.2.',
        text: "I understand that AVRillo has no control over the use of the gift money if I transfer this to the Donee's bank account, even though my intent is for my gift to be used by the Donee to purchase.",
      },
      {
        number: '1.3.',
        text: [
          {
            $cmp: TextWithClients,
            props: {
              prependText: 'I confirm that I am the Donor (the person gifting money) to the Donee, ',
              appendText: ' , (the person receiving our gift in connection with their purchase).',
            },
          },
        ],
      },
      {
        number: '1.4.',
        text: 'I confirm that where the gift money has come from any accounts or assets which are jointly owned with another person, the said gift money is coming only from my share of the joint funds; further, that the said gift is being made with the full consent of the joint owner of that money.',
      },
      {
        number: '1.5.',
        text: `$: "I confirm the amount of my gift is, £" + (${`$conveyancingCase.gift_amount`} || '') + " , and I am making this gift to assist the Done in their purchase of"`,
      },
      {
        number: '1.6.',
        text: `$: "I confirm my relationship with the Donee is such that the sole reason for this gift is the natural love and affection that exists between us. I understand the Donee has described our relationship with them as " + (${`$conveyancingCase.relation`} || '') + ". I confirm that this description is correct."`,
      },
      {
        number: '1.7.',
        text: 'I confirm my gift is not a loan but, I repeat, an unconditional gift made by me for pure natural love and affection that exists between myself and the Donee.',
      },
      {
        number: '1.8.',
        text: 'I make this gift for nil consideration.',
      },
      {
        number: '1.9.',
        text: 'I confirm that I attach no conditions to my gift to the Donee.',
      },
      {
        number: '1.10.',
        text: 'I confirm that neither myself nor my successors and in the title nor my estate will make a claim on the gift of the property the Donee is buying with the assistance of my gift or the sale proceeds of that property is subsequently sold, nor will I acquire such rights in any subsequent property purchased with such sale proceeds.',
      },
      {
        number: '1.11.',
        text: 'The Donee will not pay interest on my gift.',
      },
      {
        number: '1.12.',
        text: 'The Donee will not repay the gift.',
      },
      {
        number: '1.13.',
        text: 'I confirm that neither I, nor my estate or successors in title, acquire any ownership, interest, or rights (whether legal, equitable or implied) over the gift or in the property being purchased neither now nor in the future, nor will I attempt to make such a claim in the future. I, therefore, confirm that I have no control, right or say in, for example, what works can or cannot be carried out at the property, who is entitled to visit or reside at the property, if when it is sold. Nor will I have any rights to live at the property or subsequent property purchased if there is a future sale of the property.',
      },
    ]),
  }
}
