<template>
  <el-card body-class="px-6" class="box-card">
    <article class="prose max-w-full text-sm">
      <h1 class="mb-6 text-xl font-medium">Explanatory Notes for Sellers</h1>
      <p>
        You must read these explanatory notes before answering the questions in this form as they form part of the material information you must disclose to the
        buyer under The Consumer Protection from Unfair Trading Regulations 2008, as defined in the National Trading Standards guide, as they could influence an
        average buyer’s decision to make an informed decision to view, offer or purchase the property. As your replies are legal representations*, if they are
        incorrect, inaccurate, incomplete, or not to the best of your own knowledge and belief, the buyer can sue you for compensation and loss and even refuse
        to complete the purchase.
      </p>
      <p>
        <a
          href="https://www.nationaltradingstandards.uk/uploads/Material%20Information%20in%20Property%20Listings%20(Sales)%20v1.0.pdf"
          rel="noopener noreferrer"
          target="_blank"
        >
          Click here
        </a>
        to read the National Trading Standards definition of material information and
        <a href="https://www.lawsociety.org.uk/topics/property/transaction-forms/#TA6" rel="noopener noreferrer" target="_blank">click here</a>
        to read the Law Society guide on how to answer a protocol form. Note. Below
        <b>are a few preliminary notes and examples to</b>
        indicate
        <b>how to reply to this form.</b>
      </p>
      <ol class="numeric numeric-slash flex flex-col gap-0">
        <li>You must reply in your own words as you have the knowledge and information about the property you are selling.</li>
        <li>
          You must not ask your conveyancer to complete this form for you as you must reply in your own words as the seller**, and because, e.g., your
          conveyancer has neither inspected nor has knowledge about the property. Your responses may prompt additional questions from the buyer’s conveyancer,
          also known as additional enquiries.
        </li>
        <li>
          Your replies must be true to the best of your knowledge and belief. If you do not know the answer to a question, you must state this. Or if you are
          unsure about a reply, you can reply “not known”. Whether such replies are acceptable to the buyer will depend on them or their conveyancer.
        </li>
        <li>
          You must update the buyer if you later become aware of material information (including notices about the property arriving before the sale is
          completed) that would affect or alter your original replies by immediately amending your form so the buyer can be informed, as well as emailing
          AVRillo, who will then notify the buyers’ conveyancers. This obligation is just as important as providing accurate answers initially.
        </li>
        <li>
          You must upload documents referred to in the form. If you do not upload, please explain why so the buyer can decide what to do because they should
          already be in your possession either from when you bought the property (i.e. sent to you by your previous conveyancer) or from when you should have
          obtained these documents during your ownership of the property (e.g., for any alterations). This buyer will expect you to obtain missing documentation
          or replacements at your own expense (i.e., downloading these from relevant sites, asking those who provided these, or from your previous conveyancer.
        </li>
        <li>
          No Legal Advice: You understand that whilst every effort has been made to ensure the accuracy of this form and notes, we, as conveyancers, cannot
          accept legal responsibility as these are intended to help you provide material information you need to disclose about your property which could
          influence them to buy the property at the price agreed.
        </li>
      </ol>
      <p>
        Rider:
        <u>‘Seller’</u>
        (also referred to as vendor) is the person who owns the property on the deeds or a legal representative under, e.g., a Grant of Probate or a Power of
        Attorney. If there is more than one seller, the buyer will assume that replies from one seller are made on behalf of all the sellers and are correct to
        the knowledge and belief of all. This means all sellers must check the replies on the form to ensure they are provided to the best of their knowledge
        and belief.
      </p>
      <p>
        *&nbsp;
        <u>’Legal Representation’</u>
        includes communication and other correspondence you send to the buyer, such as conversations, letters, emails, and texts, whether directly and/or
        through the estate agent.
      </p>
    </article>
  </el-card>
</template>
