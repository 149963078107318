import { useProps } from '~/composables/useProps.js'

export const useSchema = (schema) => {
  if (schema.signature) {
    schema.properties.push({
      $cmp: 'ElFormItem',
      props: useProps({ title: 'Signatures' }),
      children: [
        {
          $el: 'div',
          if: `$formData.type === 'instruction'`,
          attrs: { class: 'grid grid-cols-1 md:grid-cols-2 gap-6 w-full' },
          children: [
            {
              $formkit: 'signature-input',
              name: `clients.client1.signature`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-input',
              if: `$formData.data.clients.client2.is_there_second_client === true`,
              name: `clients.client2.signature`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $el: 'div',
          if: `!$saleReadyPack.uuid && $formData.type !== 'instruction'`,
          attrs: { class: 'grid grid-cols-1 md:grid-cols-2 gap-6 w-full' },
          children: [
            {
              $formkit: 'signature-input',
              if: '$conveyancingCase.client',
              name: `signature_client`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-input',
              if: '$conveyancingCase.client2',
              name: `signature_client2`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-input',
              if: '$conveyancingCase.client3',
              name: `signature_client3`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-input',
              if: '$conveyancingCase.client4',
              name: `signature_client4`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
        {
          $el: 'div',
          if: `$saleReadyPack.uuid && $formData.type !== 'instruction'`,
          attrs: { class: 'grid grid-cols-1 md:grid-cols-2 gap-6 w-full' },
          children: [
            {
              $formkit: 'signature-sale-ready-pack-input',
              if: `$saleReadyPack.report.proprietors.0.name`,
              name: `signature_proprietor1`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-sale-ready-pack-input',
              if: `$saleReadyPack.report.proprietors.1.name`,
              name: `signature_proprietor2`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-sale-ready-pack-input',
              if: `$saleReadyPack.report.proprietors.2.name`,
              name: `signature_proprietor3`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
            {
              $formkit: 'signature-sale-ready-pack-input',
              if: `$saleReadyPack.report.proprietors.3.name`,
              name: `signature_proprietor4`,
              validation: 'required',
              validationMessages: {
                required: 'This field is required',
              },
            },
          ],
        },
      ],
    })
  }

  return schema
}
