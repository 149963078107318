export default function (parentPath) {
  return {
    $cmp: 'ElRow',
    props: { gutter: 50, class: 'mt-6' },
    children: [
      {
        $cmp: 'ElCol',
        props: { span: 24 },
        children: [
          {
            $formkit: 'el-checkbox-group',
            attrs: {
              class: 'flex flex-inline',
            },
            name: `${parentPath}.differed_option_scheme`,
            options: [{ label: 'Tick to opt for our Deferred Option Scheme: ', value: 'accepted' }],
          },
          {
            $el: 'div',
            attrs: { class: 'mb-2' },
            children:
              'I understand that if I tick this option then, I will automatically be eligible for AVRillo’s ‘ Deferred Option Scheme. The Deferred Option Scheme allows you to progress your conveyancing without having to pay third party costs upfront before completion. This means that although certain third party costs are payable as soon as they are incurred during the conveyancing process ( these third party fees can vary widely depending on the specifics of each case) by ticking this box I have selected the Deferred Option Scheme which means AVRillo will pay all third party payments required in their doing the conveyancing up to the value of £1000 on each case. In selecting I agree to instruct AVRillo to obtain Local, Water, Environmental, Chancel and Flood searches ( but where I decide I do not want these I will notify you within 48 hours of my instruction). You can use deferred payment to include any AVRillo charge involved in making those payments to third parties ( other than payments relating to third party for defective deeds). These payments will only be collected from me at completion (or earlier if your case does not proceed). Where selling and buying please be reassured that by ticking this box you are selecting to apply deferred payment scheme charge to each case so it will give you up to £2000 in third party fees collectively. I understand that if I don’t tick the box, I will need to pay these third party payments immediately that they are requested. I understand there is no upfront payment for ‘Deferred Option Scheme’ (it is simply added to the end -see estimate attached, £97 plus vat) which secures my costs protection to the end.',
          },
        ],
      },
    ],
  }
}
